import React, { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  TextField,
  FormControl,
  FormHelperText,
  Button,
  InputAdornment,
} from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";

import ApiConfig from "src/ApiConfig/ApiConfig";
import * as yep from "yup";
import { Formik, ErrorMessage, Form } from "formik";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
const useStyles = makeStyles((theme) => ({
  updateSection: {
    padding: "70px 0px",
    background: "linear-gradient(90.07deg, #f3bb2f 24.53%, #f0c354 88.81%)",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 0px",
    },
    "& .shape": {
      position: "absolute",
      top: "70px",
      left: "100px",
    },
    "& .shape1": {
      position: "absolute",
      top: "60px",
      left: "180px",
    },
    "& .shape2": {
      position: "absolute",
      bottom: "-25px",
      left: "44%",
    },
    "& .shape3": {
      right: "0px",
      bottom: 0,
      position: "absolute",
      width: "65px",
    },
    "& .shape4": {
      position: "absolute",
      right: "15%",
      top: "25px",
    },
    "& .shape5": {
      position: "absolute",
      right: "8%",
      bottom: "25px",
    },
    "& .emailBox": {
      position: "relative",
      "& button": {
        background:
          "linear-gradient(261.87deg, #f3bb2f 13.12%, #eca901 83.57%)",
        borderRadius: "44px 0px 0px 44px",
        color: "#fff",
        minWidth: "100px",
        height: "40px",
        top: "5px",
        right: "5px",
        position: "absolute",
      },
    },
    "& input": {
      backgroundColor: "#fff",
      borderRadius: "5px",
      border: " none",
      height: "50px",
      padding: "0 15px",
      paddingRight: "111px"
    },
    "& h2": {
      fontWeight: 600,
      color: "#fff",
      "& img": {
        marginRight: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
  },
}));

export default function BestSeller(props) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  // const formInitialSchema = {
  //   email: "",
  // };
  // const formValidationSchema = yup.object().shape({
  //   email: yup
  //     .string()
  //     .email("You have entered an invalid email address. Please try again")
  //     // .required("Email address is required")
  //     .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
  // });
  const formInitialSchema = {
    email: "",
  };
  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .required(" ")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid Email address"
      ),
  });

  const handleFormSubmit = async (values) => {
    try {
      const res = await axios({
        method: "POST",
        url: 'https://aiml-nftmarketplace.mobiloitte.com/getemail/',
        data: {
          email: values.email,
        },
      });
      if (res.data.status_code === 200) {
        toast.info("Thanks for Signing up for updates");
        // history.push({
        //     pathname: "/",
        //     state: { email: values.email },
        // });
      } else {

        toast.warn(res.data.message);
      }
    } catch (error) {

      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }

  }
  return (
    <Box className={classes.updateSection}>
      <img src="images/shape/shape-7.png" className="shape moveTop" />
      <img src="images/shape/shape-8.png" className="shape1 rotate" />
      <img src="images/shape/shape-9.png" className="shape2 rotate" />
      <img src="images/shape/shape-9.png" className="shape4 rotate" />
      <img src="images/shape/shape-10.png" className="shape3 moveTop" />
      <img src="images/shape/shape-8.png" className="shape5 moveLeft" />
      <Container maxWidth="md">
        <Grid
          container
          spacing={2}
          style={{ zIndex: "1111" }}
          alignItems="center"
        >
          <Grid item xs={12} sm={6} align="center">
            <Typography variant="h2">GET THE LATEST UPDATES</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className="emailBox">
              <Formik
                initialValues={formInitialSchema}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={formValidationSchema}
                onSubmit={(values, { resetForm }) =>
                  handleFormSubmit(values, resetForm({ values: "" }))
                }
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <Form onSubmit={(values) => handleSubmit(values)}>
                    <FormControl fullWidth>
                      <TextField
                        variant="outlined"
                        name="email"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="outlined-basic"
                        placeholder="Enter your email"
                        inputProps={{ maxLength: 256 }}
                        fullWidth
                      />
                      <FormHelperText error style={{ color: "rgb(239 18 18)" }}>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </FormControl>
                    <Button
                      type="submit"
                      className="searchBtn"
                      disabled={isUpdating}
                    >
                      I’m In {isUpdating && <ButtonCircularProgress />}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
