import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  FormControl,
  Typography,
  Menu,
  InputBase,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardContent,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ethers } from "ethers";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import { NavLink } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { sortAddress } from "src/utils";
import SearchBox from "src/layouts/HomeLayout/DashboardLayout/SearchBox";
import { BsFillCaretDownFill } from "react-icons/bs";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Target } from "react-feather";
import Axios from "axios";
const headersData = [
  // {
  //   label: "Add Branding",
  //   href: "/washtrading",
  // },
  {
    label: "Marketplace",
    href: "https://bsc-nftmarketplace.mobiloitte.org/",
  },
  {
    label: "Collections",
    href: "/",
  },
  // {
  //   label: "Feature",
  //   href: "/roadmap",
  // },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    // marginLeft: "15px",
    textDecoration: " none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {},
    "&:AIML": {
      color: "#f3bb2f",
    },
  },
  toolbar: {
    // display: "flex",
    // padding: "10px 0",
    // justifyContent: "space-between",
    // height: "100%",
    // "@media (max-width: 900px)": {
    //   paddingLeft: "75px",
    //   paddingRight: "20px",
    //   height: "100%",
    // },
    padding: "0px",
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "160px",
    height: "40px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: "#fff",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#000",
    paddingLeft: 0,
    // borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "40px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#00000026",
    border: "1px solid #fff",

    marginLeft: 20,
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: "0px 9px",
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
    width: "100%",
  },
  inputInput: {
    padding: "8px 6px 8px 0px",
    fontSize: "12px",
    marginTop: "-2px",
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#000",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  menuButton1: {
    paddingLeft: "0",
  },
  searcBox: {
    backgroundColor: "#00000026",
    // border: "1px solid #00000026",
    borderRadius: " 50px",
  },
  menuMobile1: {
    // marginLeft: "10px",
    backgroundColor: " #FCF2FA",
    borderRadius: "40px",
    "& h4": {
      fontSize: "14px",
      lineHeight: " 17px",
      color: "#000",
      margin: "0 6px",
    },
    "& svg": {
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:AIML": {
      backgroundColor: " #FCF2FA",
      borderRadius: "40px",
    },
    "& figure": {
      margin: 0,
      width: 40,
      height: 40,
      borderRadius: "50px",
      overflow: "hidden",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
      "& img": {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        // maxHeight: "100%",
      },
    },
  },
  menuMobile2: {
    "& h4": {
      fontSize: "16px",
      lineHeight: " 17px",
      color: "#fff",
      margin: "0 5px",
      whiteSpace: "pre",
      fontWeight: "500",
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "& svg": {
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:AIML": {
      backgroundColor: "transparent",
      color: "#f3bb2f",
    },
  },
  walletaddressbtn: {
    minWidth: "188px",
    height: "49px",
    color: "#fff !important",
    border: "1px solid #f3bb2f",
    padding: "5px 19px",
    background: "linear-gradient(261.87deg, #f3bb2f 13.12%, #eca901 83.57%)",
    borderRadius: '10px',
    "& h4": {
      fontSize: "14px !important",
      lineHeight: " 17px",
      color: "#fff",
      margin: "0 5px",
      whiteSpace: "pre",
      fontWeight: "500 !important",
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "& svg": {
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:AIML": {
      backgroundColor: "transparent",
      color: "#f3bb2f",
    },
  },
  menuMobiledraweraddress: {

    "& h4": {
      fontSize: "14px",
      lineHeight: " 17px",
      color: "#000",
      whiteSpace: "pre",
      fontWeight: "400",
    },
  },
  menuMobiledrawer: {
    "& h4": {
      fontSize: "16px",
      lineHeight: " 17px",
      color: "#000",
      whiteSpace: "pre",
      fontWeight: "400",
    },
  },
  analyticsicon: {
    padding: "0px 15px 10px 10px",
    minWidth: '40px',

  },
  analyticsmenu: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "19px",
    lineHeight: "130%",
    color: "#323232",
    paddingBottom: '0',
    paddingTop: '15px',
    paddingLeft: '15px',
  },
  addressmenu: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "19px",
    lineHeight: "130%",
    color: "#323232",
    display: 'flex',
    justifyContent: 'center',
    padding: '10px'
  },
  Mobileaddressmenu: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "130%",
    color: "#323232",
    display: 'flex',
    justifyContent: 'center',
    padding: '10px'
  },
  Cwallet: {
    color: "#000000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.75",
  },
  submenu: {
    background: '#EBFBE6'
  },
  inputlabelDiv: {
    paddingBottom: '18px'
  },
  inputBox: {
    display: 'flex',
    justifyContent: 'end',
    paddingBottom: '10px'
  },
  profileImg: {
    width: '80px',
    height: '80px',
    borderRadius: '100%'
  },
  dialogProfileDiv: {
    display: 'grid',
    justifyContent: 'center'
  },
  dialogCard: {
    padding: '29px 40px 35px 40px',
    minWidth: '486px'
  },
  inputBoxButton: {
    width: '53px',
    height: '20px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFFFFF',
    borderRadius: '2px'
  },
  inputlabelName: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
  },
  inputlabelvalue: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#1D1D1D',
  },
  nameBorderdiv: {
    border: '1px solid #B3B3B3',
    height: '39px',
    width: '100%',
    padding: '0px 8px 8px',
  }
}));

export default function Header() {
  const classes = useStyles();
  const { account, chainId } = useWeb3React();
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [updateName, setUpdateName] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const location = useLocation();
  const [networkDetails, setNetworkDetails] = React.useState([]);


  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl2(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleCloseadress = () => {
    setAnchorEl3(null);
  };

  const handleClickadress = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const {
    menuMobile,
    menuButton,
    menuButton1,
    divstake,
    toolbar,
    drawerContainer,
    menuMobile1,
    menuMobile2,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    search,
    inputInput,
    searchIcon,
    inputRoot,
    searcBox,
    menuMobiledrawer,
    marginbtn,
  } = useStyles();
  const history = useHistory();

  const [updateMinSatkeOpen, setUpdateMinSatkeOpen] = useState(false);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const StyledMenu = withStyles({
    paper: {
      marginTop: "2px",
      // background: '#EBFBE6'
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  {
    // location.pathname !== "/" && console.log(location, "location1111");
  }
  const token = localStorage.getItem("token");
  const address = localStorage.getItem("walletAddress");
  const logOut = () => {
    history.push("/")
    localStorage.clear();
    window.location.reload()
  };
  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg" className="p-0">
      <Toolbar className={toolbar}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            {femmecubatorLogo}
          </Grid>
          <Grid item xs={6} align="right">
            {getMenuButtons()}
          </Grid>
        </Grid>

        {/* {address && ( */}

        <div>
          <IconButton
            aria-label="delete"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick2}
            className={menuMobile2}
            style={{ marginLeft: "5px", marginRight: '7px' }}
            size="small"
          >
            <Typography variant="h4">Analytics</Typography>
            {/* <BsFillCaretDownFill
                style={{ color: "#000", fontSize: "16px" }}
              /> */}
          </IconButton>
        </div>

        {/* )} */}
        <div>
          <Box>
            <StyledMenu
              id="simple-menu"
              // disableScrollLock={true}
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleClose5}

            >
              <MenuItem
                onClick={() => {
                  history.push("/");
                }}
                className={classes.analyticsmenu}
              >
                <div className={classes.analyticsicon}>
                  <img src="/images/features.svg" />
                </div>
                Features Overview
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/price-prediction");
                }}
                className={classes.analyticsmenu}
              >
                <div className={classes.analyticsicon}>
                  <img src="/images/price.svg" />
                </div>
                Price Prediction
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/washtrading");
                }}
                className={classes.analyticsmenu}
              >
                <div className={classes.analyticsicon}>
                  <img src="/images/washt.svg" />
                </div>
                Wash Trading
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/fraud-detection");
                }}
                className={classes.analyticsmenu}
              >
                <div className={classes.analyticsicon}>
                  <img src="/images/fraudd.svg" />
                </div>
                Fraud Detection
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/social-hype");
                }}
                className={classes.analyticsmenu}
              >
                <div className={classes.analyticsicon}>
                  <img src="/images/socialh.svg" />
                </div>
                Social Hype
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/trend-indicator");
                }}
                className={classes.analyticsmenu}
              >
                <div className={classes.analyticsicon}>
                  <img src="/images/trend.svg" />
                </div>
                Trend Indicators
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/nft-dashboard");
                }}
                className={classes.analyticsmenu}
              >
                <div className={classes.analyticsicon}>
                  <img src="/images/washt.svg" />
                </div>
                Nft Dashboard
              </MenuItem>
            </StyledMenu>
          </Box>
        </div>
        <div>
          <pre> </pre>
        </div>
        <div style={{ display: "flex" }}>
          {address ? (
            <div>
              <IconButton
                aria-label="delete"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickadress}
                className={classes.walletaddressbtn}
                style={{ marginLeft: "5px", marginRight: '7px' }}
                size="small"
              >
                <AccountCircleIcon />
                <Typography variant="h4"> {address.slice(0, 10)}...</Typography>
              </IconButton>
              <div>
                <StyledMenu

                  // disableScrollLock={true}
                  anchorEl={anchorEl3}
                  keepMounted
                  open={Boolean(anchorEl3)}
                  onClose={handleCloseadress}
                >
                  <div>
                    <MenuItem
                      className={classes.addressmenu}
                      onClick={handleClickOpen}
                    >
                      My Profile
                    </MenuItem>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      onFocus={handleCloseadress}
                    >
                      <Card className={classes.dialogCard} >
                        <CardContent style={{ padding: '0px' }}>
                          <Box>
                            <div
                              className={classes.dialogProfileDiv}>
                              <img src="/images/AccountProfile.jpg" alt="Profile"
                                className={classes.profileImg}
                              />
                            </div>
                            <Box className={classes.inputBox} >
                              {/* <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                className={classes.inputBoxButton}
                              >
                                Edit
                              </Button> */}
                            </Box>
                          </Box>
                          <Box >
                            <div className={classes.inputlabelDiv} >
                              <label className={classes.inputlabelName}>
                                First Name
                              </label>
                              {/* <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                name="Coco"
                                value="Coco"
                                lassName={classes.inputlabelvalue}
                              /> */}
                              <div className={classes.nameBorderdiv}>
                                <p className={classes.inputlabelvalue}>Coco</p>
                              </div>
                            </div>
                            <div className={classes.inputlabelDiv}>
                              <label className={classes.inputlabelvalue}>
                                Last Name
                              </label>
                              {/* <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                name="Caktux"
                                value="Caktux"
                                className={classes.inputlabelvalue}
                              /> */}
                              <div className={classes.nameBorderdiv}>
                                <p className={classes.inputlabelvalue}>Caktux</p>
                              </div>
                            </div>
                            <div className={classes.inputlabelDiv} >
                              <label className={classes.inputlabelName}>
                                Account Address
                              </label>
                              {/* <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                name="otp"
                                value={address}
                                lassName={classes.inputlabelvalue}
                              /> */}
                              <div className={classes.nameBorderdiv}>
                                <p className={classes.inputlabelvalue}>{address}</p>
                              </div>
                            </div>
                          </Box>
                        </CardContent>
                      </Card>
                      {/* <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Disagree
                        </Button>
                        <Button onClick={handleClose} color="primary" autoFocus>
                          Agree
                        </Button>
                      </DialogActions> */}
                    </Dialog>
                  </div>
                  {/* <MenuItem
                    className={classes.addressmenu}
                  >
                    My Account
                  </MenuItem> */}
                  <MenuItem
                    className={classes.addressmenu}
                    onClick={logOut}
                  >
                    Disconnect Wallet
                  </MenuItem>
                </StyledMenu>
              </div>
            </div>
            /* <Button
              variant="contained"
              size="large"
              color="primary"
              style={{ minWidth: "188px", height: "49px" }}
              onClick={logOut}
            >
              <AccountCircleIcon />{address.slice(0, 10)}...
            </Button> */
          ) : (
            <Button
              variant="contained"
              size="large"
              color="primary"
              style={{ minWidth: "188px", height: "49px" }}
              onClick={requestAccount}
            >
              {/* <AccountCircleIcon style={token ? {color:"#ffffff"} : ""}/> */}{" "}
              Connect Wallet
            </Button>
          )}

          {/* <Box className={classes.analyticsBox}>
            <StyledMenu
              id="simple-menu"
              disableScrollLock={true}
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleClose4}
            >
              <MenuItem
                onClick={() => {
                  history.push("/profile");
                }}
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/create");
                }}
              >
                Create NFT
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/create-nfttype");
                }}
              >
                Create Physical NFT
              </MenuItem>

              <MenuItem

              >
                Disconnect
              </MenuItem>
            </StyledMenu>
          </Box> */}
          {/* {address && (
            <PowerSettingsNewIcon
              onClick={logOut}
              style={{ marginTop: "10px", marginLeft: "6px" }}
            />
          )} */}
        </div>
      </Toolbar>
      // {/* </Container> */}
    );
  };

  const [searchInput, setSearchInput] = useState("");
  const searchTextRef = React.useRef(null);

  // const onKeyDown = (event) => {
  //   // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
  //   if (event.key === "Enter") {
  //     if (searchInput !== "") {
  //       history.push({
  //         pathname: "/search",
  //         search: searchInput,
  //       });
  //       // alert(searchInput);
  //     } else {
  //       setSearchInput("");
  //       history.push({
  //         pathname: "/search",
  //         search: searchInput,
  //       });
  //       // alert("empty");
  //       // window.location.href = "/search";
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (searchInput) {
  //     // onKeyDown();
  //   }
  // }, [searchInput]);
  // useEffect(() => {
  //   searchTextRef.current.focus();
  //   if (
  //     location.pathname === "/search" &&
  //     location.search &&
  //     location.search.slice(1, location.search.length)
  //   ) {
  //     let text = location.search.slice(1, location.search.length);
  //     setSearchInput(text);
  //   }
  // }, [location]);

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" />
            {getDrawerChoices()}
            <div>
              {/* {address && ( */}
              <div>
                <div style={{ marginBottom: "30px", marginTop: "10px" }}>
                  <IconButton
                    aria-label="delete"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick2}
                    className={menuMobiledrawer}
                    size="small"
                  >
                    <Typography variant="h4">Analytics</Typography>
                    {/* <BsFillCaretDownFill
                        style={{ color: "#000", fontSize: "16px" }}
                      /> */}
                  </IconButton>
                </div>{" "}
              </div>
              {/* )} */}

              <div>
                <Box>
                  <StyledMenu
                    id="simple-menu"
                    disableScrollLock={true}
                    anchorEl={anchorEl2}
                    keepMounted
                    open={Boolean(anchorEl2)}
                    onClose={handleClose5}
                  >
                    <MenuItem
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      <div className={classes.analyticsicon}>
                        <img src="/images/features.svg" />
                      </div>
                      Features Overview
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push("/price-prediction");
                      }}
                    >
                      <div className={classes.analyticsicon}>
                        <img src="/images/price.svg" />
                      </div>
                      Price Prediction
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push("/washtrading");
                      }}
                    >
                      <div className={classes.analyticsicon}>
                        <img src="/images/washt.svg" />
                      </div>
                      Wash Trading
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push("/fraud-detection");
                      }}
                    >
                      <div className={classes.analyticsicon}>
                        <img src="/images/fraudd.svg" />
                      </div>
                      Fraud Detection
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push("/social-hype");
                      }}
                    >
                      <div className={classes.analyticsicon}>
                        <img src="/images/socialh.svg" />
                      </div>
                      Social Hype
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push("/trend-indicator");
                      }}
                    >
                      <div className={classes.analyticsicon}>
                        <img src="/images/trend.svg" />
                      </div>
                      Trend Indicators
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push("/nft-dashboard");
                      }}
                    >
                      <div className={classes.analyticsicon}>
                        <img src="/images/washt.svg" />
                      </div>
                      Nft Dashboard
                    </MenuItem>
                  </StyledMenu>
                </Box>
                {address ? (
                  <div>
                    <IconButton
                      aria-label="delete"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClickadress}
                      className={classes.menuMobiledraweraddress}
                      size="small"
                    >
                      <AccountCircleIcon />
                      <Typography variant="h4"> {address.slice(0, 10)}...</Typography>
                    </IconButton>
                    <Box style={{ background: 'F9F9F9', }}>
                      <StyledMenu
                        id="simple-menu"
                        // disableScrollLock={true}
                        anchorEl={anchorEl3}
                        keepMounted
                        open={Boolean(anchorEl3)}
                        onClose={handleCloseadress}
                      >
                        <div>
                          <MenuItem
                            className={classes.Mobileaddressmenu}
                            onClick={handleClickOpen2}
                          >
                            My Profile
                          </MenuItem>
                          <Dialog
                            open={open2}
                            onClose={handleClose2}
                            onFocus={handleCloseadress}
                          >
                            <Card className={classes.dialogCard} >
                              <CardContent style={{ padding: '0px' }}>
                                <Box>
                                  <div
                                    className={classes.dialogProfileDiv}>
                                    <img src="/images/AccountProfile.jpg" alt="Profile"
                                      className={classes.profileImg}
                                    />
                                  </div>
                                  <Box className={classes.inputBox} >
                                    <Button
                                      variant="contained"
                                      size="large"
                                      color="primary"
                                      className={classes.inputBoxButton}
                                    >
                                      Edit
                                    </Button>
                                  </Box>
                                </Box>
                                <Box >
                                  <div className={classes.inputlabelDiv} >
                                    <label className={classes.inputlabelName}>
                                      First Name
                                    </label>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      name="Coco"
                                      value="Coco"
                                      lassName={classes.inputlabelvalue}
                                    />
                                  </div>
                                  <div className={classes.inputlabelDiv}>
                                    <label className={classes.inputlabelName}>
                                      Last Name
                                    </label>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      name="Caktux"
                                      value="Caktux"
                                      className={classes.inputlabelvalue}
                                    />
                                  </div>
                                  <div className={classes.inputlabelDiv} >
                                    <label className={classes.inputlabelName}>
                                      Account Address
                                    </label>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      name="otp"
                                      value={address}
                                      lassName={classes.inputlabelvalue}
                                    />
                                  </div>
                                </Box>
                              </CardContent>
                            </Card>
                            {/* <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Disagree
                        </Button>
                        <Button onClick={handleClose} color="primary" autoFocus>
                          Agree
                        </Button>
                      </DialogActions> */}
                          </Dialog>
                        </div>
                        {/* <MenuItem
                          className={classes.Mobileaddressmenu}
                        >
                          My Account
                        </MenuItem> */}
                        <MenuItem
                          className={classes.Mobileaddressmenu}
                          onClick={logOut}
                        >
                          Disconnect Wallet
                        </MenuItem>
                      </StyledMenu>
                    </Box>
                  </div>
                  /* <MenuItem className={classes.Cwallet} onClick={logOut}>
                    Disconnect Wallet

                  </MenuItem> */
                ) : (
                  <MenuItem
                    onClick={requestAccount}
                    className={classes.Cwallet}
                  >
                    Connect Wallet
                  </MenuItem>
                )}
                {/* {address && (
                <MenuItem           onClick={logOut}           className={classes.Cwallet}>
                  {" "}
            
                    Disconnect Wallet
               
                </MenuItem>   )} */}
              </div>
              {/* <IconButton
                aria-label="delete"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick1}
                className={menuMobile1}
                size="small"
              >
                <figure
                  style={

                    { height: "40px", width: "40px" }

                  }
                >
                  <img
                    src="/images/Profile.png"
                    
                  />
                </figure>
                <Typography
                  variant="h4"

                >
                  {" "}

                </Typography>
                <BsFillCaretDownFill
                  style={{ color: "#000", fontSize: "16px" }}
                />
              </IconButton>


              <Box className={classes.analyticsBox}>
                <Menu
                  id="simple-menu"
                  disableScrollLock={true}
                  anchorEl={anchorEl1}
                  keepMounted
                  open={Boolean(anchorEl1)}
                  onClose={handleClose4}
                >
                  <MenuItem
                    onClick={() => {
                      history.push("/profile");
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push("/create");
                    }}
                  >
                    Create NFT
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push("/create-nfttype");
                    }}
                  >
                    Create Physical NFT
                  </MenuItem>

                  <MenuItem

                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Box> */}
            </div>
          </div>
        </Drawer>

        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Box>{femmecubatorLogo}</Box>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#197ab3", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {

    return (
      <>
        <Button

          href="https://bsc-nftmarketplace.mobiloitte.org/"
          target='_blank'
        >
          <MenuItem className={menuMobile}>Marketplace</MenuItem>
        </Button>
        {/* {address && ( */}
        <Button
          {...{

            color: "inherit",
            to: "/my-collection",
            component: Link,
            className: menuButton1,
          }}
        >
          <MenuItem className={menuMobile}>Collections</MenuItem>
        </Button>
        {/* )} */}
      </>
    );

  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" style={{ width: "160px", height: "40px" }} />
      </Link>
    </Box>
  );

  const SearchBoxx = (
    <SearchBox
      search={search}
      inputRoot={inputRoot}
      inputInput={inputInput}
      searchIcon={searchIcon}
    />
  );

  const getMenuButtons = (activeClassName) => {
    const handleLink = (() => {
      // window.location.href = 'https://bsc-nftmarketplace.mobiloitte.org/';
      window.open('https://bsc-nftmarketplace.mobiloitte.org/', '_blank');
    })
    return (
      <>
        <NavLink
          exact
          // to={`${href}`}
          {...{
            color: "inherit",
            to: "/",
            // component: Link,
            className: menuButton,
            activeClassName: "active",
          }}
          onClick={handleLink}

        >
          {" "}
          Marketplace
        </NavLink>
        {/* {address && ( */}
        <NavLink
          exact
          // to={`${href}`}
          {...{

            color: "inherit",
            to: "/my-collection",
            // component: Link,
            className: menuButton,
            activeClassName: "active",
          }}
        >
          {" "}
          Collections
        </NavLink>
        {/* )} */}
      </>
    );

  };
  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWalletAddress(accounts[0]);
        localStorage.setItem("walletAddress", accounts[0]);
        window.location.reload()
        // history.push("/login");
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      alert("Meta Mask not detected");
    }
  }

  // Create a provider to interact with a smart contract
  async function connectWallet() {
    if (typeof window.ethereum !== "undefined") {
      await requestAccount();

      const provider = new ethers.providers.Web3Provider(window.ethereum);
    }
  }

  const getToken = async () => {

    const address = localStorage.getItem("walletAddress");

    try {
      const res = await Axios({
        method: "POST",
        url: `https://aiml-development.mobiloitte.io/bscintegration/connectwallet/`,
        data: {
          walletaddress: address,
        },

      });
      // console.log("order data==>", res);
      if (res.data.status_code == 200) {
        console.log("order data==>", res.data.data);
        localStorage.setItem("token", res.data.data.result.token);
      }
    } catch (error) {
      console.log("userData==>", error);

    }
  };
  useEffect(() => {
    getToken()
  }, [])
  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#121212", border: "none" }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
