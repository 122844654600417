export const baseurl = "https://aiml-nftmarketplace.mobiloitte.com/";
let url = baseurl;
let localurl = "https://aiml-development.mobiloitte.io/bscintegration";


const Apiconfigs = {

  //market-and-collection
  totalNftCount: `${localurl}/totalnftcount/`,

  //price-prediction
  price: `${url}/price/`,
  volumeprediction: `${url}/volumeprediction/`,
  nftList: `${localurl}/collectionlist/`,
  localprice: `${localurl}/priceprediction/`,


  //washtrading
  Transaction: `${url}/Transaction/`,
  washtrade: `${url}/washtrade/`,
  totalvolumetrade: `${url}/totalvolumetrade/`,


  //social-hype
  socialmediahype: `${url}/socialmediahype/`,


  //trend-indicator
  scorevstime: `${url}/scorevstime/`,
  topcollection: `${url}/topcollection/`,
  worstcollection: `${url}/worstcollection/`,

  //nft-dashboard
  getTransaction: `${url}/Transaction/`,
  TransactionVsCollection: `${url}/transactionvscollection/`,
  uniqueWallet: `${url}/scorevstime/`,
  TopWorst: `${url}/worstcollection/`,
  TopNft: `${url}/topcollection/`,
  topnftbuyers: `${url}/topnftbuyers/`,
  topnftseller: `${localurl}/topseller/`,
  // totalCollectionTable: `${url}/totalcollection/`,
  totaltotalnftTable: `${localurl}/totalnft/`,
  totalCollectionTable: `${localurl}/collectionlist/`,

  //my-collection
  mycollection: `${localurl}/mycolletion/`,
  topBuyers: `${localurl}/topbuyers/`,

  //FraudDetection
  frauddetection: `${localurl}/frauddetection`,

};

export default Apiconfigs;
