import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
// import DashboardLayout from "src/layouts/DashboardLayout";
import DashboardLayout from "./layouts/HomeLayout/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },

  {
    exact: true,
    path: "/price-prediction",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/PhysicalNFT/BrandList")),
  },
  {
    // guard: true,
    exact: true,
    path: "/washtrading",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/PhysicalNFT/Addyourbrand")),
  },
  {
    exact: true,
    path: "/fraud-detection",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/PhysicalNFT/BrandCollectionList")
    ),
  },
  {
    exact: true,
    path: "/social-hype",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/PhysicalNFT/SocialHype")
    ),
  },
  {
    exact: true,
    path: "/trend-indicator",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/PhysicalNFT/TrendIndicator")
    ),
  },
  {
    exact: true,
    path: "/nft-dashboard",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/PhysicalNFT/NftDashboard")
    ),
  },
  {
    exact: true,
    path: "/my-collection",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/User/MyCollection")

    ),
  },
  {
    exact: true,
    path: "/faqs",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/PhysicalNFT/FAQ")
    ),
  },
  {
    exact: true,
    path: "/all-faqs",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/PhysicalNFT/AllFAQ")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/layouts/HomeLayout/Privacy")
    ),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/layouts/HomeLayout/Term")
    ),
  },
  // {
  //   exact: true,
  //   path: "/login",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/auth/Login/index")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/forgot",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/auth/Login/Forgot")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/otp-verification",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/auth/Login/OtpVerification")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/otp-verify",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/auth/Login/OtpVerificationResetPassword")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/sign-up",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/auth/SignUp/signup")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/reset-password",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/auth/Login/Reset")
  //   ),
  // },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
